<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="submit">
            <b-card header="Login" header-bg-variant="primary" header-text-variant="white" class="card-login">
                <ValidationProvider :rules="{required: true}" name="Username">
                    <b-form-group label="Username" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.UserName" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider :rules="{required: true}" name="Password">
                    <b-form-group label="Password" slot-scope="{ valid, errors }">
                        <b-form-input type="password" v-model="form.Password" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
                <b-button type="submit" block variant="outline-primary" :disabled="loading">
                    <i v-if="loading" class="fas fa-spinner fa-spin"></i> Login
                </b-button>
                <div class="text-danger text-center mt-3">{{error}}</div>
                <hr/>
                <div class="text-center">
                    <b-link to="forgot-password">Forgot Password?</b-link>
                </div>
            </b-card>
        </form>
    </ValidationObserver>
</template>
<script>
    export default {
        props: {
            nextUrl: {
                default: '/'
            }
        },
        data() {
            return {
                form: {},
                loading: false,
                error: null,
            };
        },
        methods: {
            async submit() {
                const valid = await this.$refs.form.validate();
                if (valid) {
                    this.error = null,
                    this.loading = true;
                    const response = await this.$remote.request('Identity', 'Authenticate', this.form);
                    if (response) {
                        await this.$identity.setAuthorization(response);
                        this.$router.replace(this.nextUrl);
                    } else {
                        this.error = "unknown username or password";
                    }
                    this.loading = false;
                }
            },
        }
    }
</script>
<style scoped>
    .card-login {
        max-width: 400px;
        position: fixed;
        top: 40%;
        left: 55%;
        margin-top: -100px;
        margin-left: -200px;
    }
</style>